import { QueryKey } from '@tanstack/react-query';
import apiClient from '../../../services/apiClient';
import {
  EntryFilter,
  EntryRequest,
  EntryRequestResponse,
} from '../types/entry-response';
import { TransformFilters } from '../../../utils';

export const EntryReqAPI = {
  gets: async ({
    queryKey,
  }: {
    queryKey: QueryKey;
  }): Promise<EntryRequestResponse> => {
    const [, filters] = queryKey as [string, EntryFilter?];
    const transformedFilters = TransformFilters(filters);
    const response = await apiClient.get('/api/petition/entry', {
      params: {
        ...transformedFilters,
        paginate: true,
        page: filters?.page,
      },
    });
    return response.data;
  },
  get: async (id: string): Promise<EntryRequest> => {
    const response = await apiClient.get(`/api/petition/entry/${id}`);
    return response.data;
  },
  put: async (
    id: string,
    data: Record<string, EntryRequest>
  ): Promise<void> => {
    const response = await apiClient.put(`/api/petition/entry/${id}`, data);
    return response.data;
  },
  post: async (req: EntryRequest): Promise<void> => {
    const response = await apiClient.post(
      '/api/petition/entry',
      {
        ...req,
        documents: req?.documents?.map((doc) => doc?.file) || [],
      },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return response.data;
  },
  delete: async (id: string): Promise<void> => {
    const response = await apiClient.delete(`/api/petition/entry/${id}`);
    return response.data;
  },
  export: async (ids: string[]): Promise<void> => {
    const params = ids.map((id) => `ids[]=${id}`).join('&');
    const response = await apiClient.get(
      `/api/petition/entry/export?${params}`,
      {
        responseType: 'blob',
      }
    );
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'exported_entries.xlsx');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    return response.data;
  },
};
