import apiClient from "../../../services/apiClient";
import { TransformFilters } from '../../../utils';
import { QueryKey } from "@tanstack/react-query";
import { RefundsFilter, RefundsRequest, RefundsResponse } from "../types/refunds-response";

export const refundsReqAPI = {
  gets: async ({
    queryKey,
  }: {
    queryKey: QueryKey;
  }): Promise<RefundsResponse> => {
    const [, filters] = queryKey as [string, RefundsFilter?];
    const transformedFilters = TransformFilters(filters);
    const response = await apiClient.get('/api/petition/refund', {
      params: {
        ...transformedFilters,
        paginate: true,
        page: filters?.page,
      },
    });
    return response.data;
  },
  get: async (id: string): Promise<RefundsRequest> => {
    const response = await apiClient.get(`/api/petition/refund/${id}`);
    return response.data;
  },
  put: async (
    id: string,
    data: Record<string, RefundsRequest>
  ): Promise<void> => {
    const response = await apiClient.put(`/api/petition/refund/${id}`, data);
    return response.data;
  },
  post: async (req: RefundsRequest): Promise<void> => {
    const response = await apiClient.post(
      '/api/petition/refund',
      {
        ...req,
        documents: req?.documents?.map((doc) => doc?.file) || [],
      },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return response.data;
  },
  export: async (ids: string[]): Promise<void> => {
    const params = ids.map((id) => `ids[]=${id}`).join('&');
    const response = await apiClient.get(`/api/petition/refund/export?${params}`, {
      responseType: 'blob',
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'exported_refund.xlsx');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    return response.data;
  },
};
