import React from 'react';
import { Row, Col, Typography, Divider } from 'antd';
import {
  CustomTextArea,
  FormDatePicker,
  FormInput,
  FormSelect,
  UploadWithTable,
} from '../../../../components';
import { UploadedFile } from '../../../../utils';
import { AuthResponse } from '../../../Auth/types';
import { ShareResponse } from '../../../../share';

const { Title } = Typography;

interface EntryRequestCreateViewProps {
  formik: any;
  entyTypes?: ShareResponse[];
  itemTypes?: ShareResponse[];
  entryStates?: ShareResponse[];
  goodStates?: ShareResponse[];
  entryDevelivery?: ShareResponse[];
  sedes?: ShareResponse[];
  dependencies?: ShareResponse[];
  supervisors?: ShareResponse[];
  onSave: () => void;
  onCancel: () => void;
  authData?: AuthResponse | null;
  onFiles: (files: UploadedFile[]) => void;
  files?: UploadedFile[];
}

const EntryRequestCreateView: React.FC<EntryRequestCreateViewProps> = ({
  formik,
  entyTypes,
  itemTypes,
  entryDevelivery,
  entryStates,
  sedes,
  supervisors,
  authData,
  files,
  onFiles,
}) => {
  return (
    <>
      <Row gutter={[16, 16]} style={{ marginBottom: '20px' }}>
        <Col xs={24} sm={12} md={8} lg={6}>
          <FormInput
            label="Solicitante"
            name="requester_id"
            placeholder="Solicitante"
            value={`${authData?.user.name} ${authData?.user.last_name}`}
            disabled
            error={formik.errors.requester_id}
            touched={formik.touched.requester_id}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Col>
        <Col xs={24} sm={12} md={8} lg={6}>
          <FormSelect
            label="Tipo de entrada"
            name="entry_type"
            placeholder="Seleccione"
            value={formik.values.entry_type}
            options={entyTypes}
            error={formik.errors.entry_type}
            touched={formik.touched.entry_type}
            onChange={(value) => formik.setFieldValue('entry_type', value)}
            onBlur={formik.handleBlur}
          />
        </Col>
        <Col xs={24} sm={12} md={8} lg={6}>
          <FormSelect
            label="Entregas Estipuladas"
            name="other_deliver"
            placeholder="Seleccione"
            value={formik.values.other_deliver}
            options={entryDevelivery}
            error={formik.errors.other_deliver}
            touched={formik.touched.other_deliver}
            onChange={(value) => formik.setFieldValue('other_deliver', value)}
            onBlur={formik.handleBlur}
          />
        </Col>
        <Col xs={24} sm={12} md={8} lg={6}>
          <FormSelect
            label="Seccional/Sede"
            name="branch_id"
            placeholder="Seleccione"
            value={formik.values.branch_id}
            options={sedes}
            error={formik.errors.branch_id}
            touched={formik.touched.branch_id}
            onChange={(value) => formik.setFieldValue('branch_id', value)}
            onBlur={formik.handleBlur}
          />
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col xs={24} sm={12} md={8} lg={8}>
          <FormSelect
            label="Tipo de bien"
            name="item_type"
            placeholder="Seleccione"
            value={formik.values.item_type}
            options={itemTypes}
            error={formik.errors.item_type}
            touched={formik.touched.item_type}
            onChange={(value) => formik.setFieldValue('item_type', value)}
            onBlur={formik.handleBlur}
          />
        </Col>
        <Col xs={24} sm={12} md={8} lg={8}>
          <FormSelect
            label="Seccional entrada"
            name="section_id"
            placeholder="Seleccione"
            value={formik.values.section_id}
            options={sedes}
            error={formik.errors.section_id}
            touched={formik.touched.section_id}
            onChange={(value) => formik.setFieldValue('section_id', value)}
            onBlur={formik.handleBlur}
          />
        </Col>
        <Col xs={24} sm={12} md={8} lg={8}>
          <FormSelect
            label="Entregas en otras secciones"
            name="deliveries"
            placeholder="Seleccione"
            value={formik.values.deliveries}
            options={entryDevelivery}
            error={formik.errors.deliveries}
            touched={formik.touched.deliveries}
            onChange={(value) => formik.setFieldValue('deliveries', value)}
            onBlur={formik.handleBlur}
          />
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col xs={24} sm={12} md={8} lg={6}>
          <FormSelect
            label="Supervisor"
            name="supervisor_id"
            placeholder="Seleccione"
            value={formik.values.supervisor_id}
            options={supervisors}
            error={formik.errors.supervisor_id}
            touched={formik.touched.supervisor_id}
            onChange={(value) => formik.setFieldValue('supervisor_id', value)}
            onBlur={formik.handleBlur}
          />
        </Col>
        <Col xs={24} sm={12} md={8} lg={6}>
          <FormInput
            label="Factura"
            name="invoice_number"
            placeholder="Inserte"
            value={formik.values.invoice_number}
            error={formik.errors.invoice_number}
            touched={formik.touched.invoice_number}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Col>
        <Col xs={24} sm={12} md={8} lg={6}>
          <FormDatePicker
            label="Fecha Factura"
            name="invoice_date"
            value={formik.values.invoice_date}
            error={formik.errors.invoice_date}
            touched={formik.touched.invoice_date}
            onChange={(value) => formik.setFieldValue('invoice_date', value)}
            onBlur={formik.handleBlur}
          />
        </Col>
        <Col xs={24} sm={12} md={8} lg={6}>
          <FormInput
            label="Valor factura"
            name="invoice_value"
            type="number"
            placeholder="Valor factura"
            value={formik.values.invoice_value}
            error={formik.errors.invoice_value}
            touched={formik.touched.invoice_value}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <CustomTextArea
            label="Observaciones"
            name="observations"
            placeholder="Observaciones"
            value={formik.values.observations}
            error={formik.errors.observations}
            touched={formik.touched.observations}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Col>
      </Row>

      <Divider />
      <Title level={4} style={{ textAlign: 'center', marginBottom: '20px' }}>
        Documentos cargados
      </Title>
      <Row>
        <Col xs={24}>
          <UploadWithTable onFilesChange={onFiles} files={files} />
        </Col>
      </Row>
    </>
  );
};

export default EntryRequestCreateView;
