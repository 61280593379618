import apiClient from '../../../services/apiClient';

export const AuthTransfersAPI = {
  login: async (credentials: { email: string; password: string }) => {
    const response = await apiClient.post('/api/login', credentials);
    return response.data;
  },

  logout: async () => {
    return await apiClient.post('/api/logout');
  },
};
