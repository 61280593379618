import React, { useRef } from 'react';
import { Formik, FormikProps } from 'formik';
import AuthView from '../Views/AuthView';
import { loginValidationSchema } from '../../../../utils/validators/authValidator';
import { useLogin } from '../../../../hooks';

const AuthContainer: React.FC = () => {
  const { mutateAsync } = useLogin();
  const formikRef =
    useRef<FormikProps<{ email: string; password: string }>>(null);

  const handleLogin = async (values: { email: string; password: string }) => {
    await mutateAsync({
      email: values.email,
      password: values.password,
    });
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={{ email: '', password: '' }}
      validationSchema={loginValidationSchema}
      onSubmit={handleLogin}
    >
      {(formik) => <AuthView formik={formik} />}
    </Formik>
  );
};

export default AuthContainer;
