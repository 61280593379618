import React from "react";
import { Typography, Card } from "antd";

const { Title, Paragraph } = Typography;

interface DashboardViewProps {
  message: string;
}

const DashboardView: React.FC<DashboardViewProps> = ({ message }) => {
  return (
    <Card>
      <Title level={2} style={{ textAlign: "center" }}>
        Dashboard
      </Title>
      <Paragraph style={{ textAlign: "center" }}>{message}</Paragraph>
    </Card>
  );
};

export default DashboardView;
