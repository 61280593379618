import React from "react";
import { Typography } from "antd";

const { Title, Text } = Typography;

interface CustomLabelProps {
  text: string;
  level?: 1 | 2 | 3 | 4 | 5 | 6;
  isParagraph?: boolean;
  className?: string;
  align?: "left" | "center" | "right";
  underline?: boolean;
}

const CustomLabel: React.FC<CustomLabelProps> = ({
  text,
  level = 3,
  isParagraph = false,
  className = "",
  align = "left",
  underline = false,
}) => {
  const customClass = `custom-label ${className} ${
    underline ? "custom-label-underline" : ""
  } custom-label-align-${align}`;

  if (isParagraph || level === 6) {
    return <Text className={customClass}>{text}</Text>;
  }

  return (
    <Title level={level as 1 | 2 | 3 | 4 | 5} className={customClass}>
      {text}
    </Title>
  );
};

export default CustomLabel;
