import React from 'react';
import { Row, Col, Typography, Form, Input, Button } from 'antd';
import { FormikProps } from 'formik';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { logo, bgLogin } from '../../../../assets/logos';

const { Title, Text } = Typography;

interface AuthViewProps {
  formik: FormikProps<{ email: string; password: string }>;
}

const AuthView: React.FC<AuthViewProps> = ({ formik }) => {
  return (
    <Row style={{ minHeight: '100vh' }}>
      {/* Sección izquierda (fondo) */}
      <Col
        xs={0}
        md={12}
        className="container-img"
        style={{
          backgroundImage: `url(${bgLogin})`,
        }}
      ></Col>

      {/* Sección derecha (formulario) */}
      <Col xs={24} md={12} className="auth-right container-auth">
        <div className="auth-form-container">
          <div className="auth-header">
            <img
              src={logo}
              alt="Fiscalía"
              className="header-logo mb-16"
              width={200}
              height={70}
            />
            <Title level={1} className="auth-title">
              Bienvenido de nuevo
            </Title>
            <Text type="secondary">Ingrese los datos para continuar</Text>
          </div>

          <Form
            layout="vertical"
            onFinish={formik.handleSubmit}
            className="auth-form"
          >
            <Form.Item
              label="Correo electrónico"
              validateStatus={
                formik.errors.email && formik.touched.email ? 'error' : ''
              }
              help={formik.touched.email && formik.errors.email}
            >
              <Input
                name="email"
                placeholder="Ingrese su correo electrónico"
                prefix={<UserOutlined />}
                size="large"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Form.Item>

            <Form.Item
              label="Contraseña"
              validateStatus={
                formik.errors.password && formik.touched.password ? 'error' : ''
              }
              help={formik.touched.password && formik.errors.password}
            >
              <Input.Password
                name="password"
                placeholder="**********"
                prefix={<LockOutlined />}
                size="large"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                block
                size="large"
                loading={formik.isSubmitting}
              >
                Iniciar Sesión
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Col>
    </Row>
  );
};

export default AuthView;
