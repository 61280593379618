import * as Yup from 'yup';
import { useFormik } from 'formik';
import { GoodsRequest } from '../../../modules/Goods/types';

export const goodsRequestValidationSchema = Yup.object({
  id: Yup.string(),

  dependency_id: Yup.string().required('Requerido'),
  location_id: Yup.string().required('Requerido'),
  goods_type: Yup.string().required('Requerido'),
  request_type: Yup.string(),
  status_id: Yup.string().required('Requerido'),
  goods_location_id: Yup.string(),
  observations: Yup.string(),
  item_id: Yup.string(),
  item_quantity: Yup.number(),
  observation: Yup.string(),
});

interface UseGoodsRequestFormikProps {
  onSubmit: (values: GoodsRequest) => void;
}

const useGoodsRequestFormik = ({ onSubmit }: UseGoodsRequestFormikProps) => {
  return useFormik<GoodsRequest>({
    initialValues: {
      id: '',
      dependency_id: '',
      location_id: '',
      request_type: '',
      status_id: '',
      goods_location_id: '',
      goods_type: '',
      observations: '',
      items: [],
    },
    validationSchema: goodsRequestValidationSchema,
    onSubmit,
  });
};

export default useGoodsRequestFormik;
