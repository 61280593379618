import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { RefundsFilter, RefundsResponse } from "../../modules/Refunds/types/refunds-response";
import { refundsReqAPI } from "../../modules/Refunds/api/refundsAPI";
import { useCustomToast } from "../../components";

// Query key for caching
const keyQuery = ['refunds'] as const;

export const useFetchRefundRequests = (filters?: Partial<RefundsFilter>) => {
  return useQuery<RefundsResponse, Error>({
    queryKey: [keyQuery, filters || {}],
    queryFn: ({ queryKey }) => refundsReqAPI.gets({ queryKey }),
    enabled: true,
  });
};

// Fetch a single refund request by ID
export const useFetchRefundById = (id?: string) => {
  return useQuery({
    queryKey: [...keyQuery, id],
    queryFn: () => (id ? refundsReqAPI.get(id) : Promise.resolve(undefined)),
    enabled: !!id,
  });
};

// Create a new refund request
export const useCreateRefund = () => {
  const { showToast } = useCustomToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: refundsReqAPI.post,
    onSuccess: () => {
      showToast('success', 'Refund Created');
      queryClient.invalidateQueries({ queryKey: [keyQuery, {}] });
    },
  });
};

//Export excel

export const useExportRefund = () => {
  const { showToast } = useCustomToast();
  return useMutation({
    mutationFn: (ids: string[]) => refundsReqAPI.export(ids),
    onSuccess: () => {
      showToast('success', 'Export successful');
    },
    onError: () => {
      showToast('error', 'Failed to export');
    },
  });
};
