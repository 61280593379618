import { useFormik } from 'formik';
import * as Yup from 'yup';

interface UseFormikEntryFiltersProps {
  onSubmit: (values: any) => void;
}

const useFormikEntryFilters = ({ onSubmit }: UseFormikEntryFiltersProps) => {
  const initialValues = {
    ticket: '',
    requester_id: '',
    status_id: '',
    created_at: '',
  };

  const validationSchema = Yup.object({
    id: Yup.string(),
    requester_id: Yup.string(),
    status_id: Yup.string(),
    created_at: Yup.date(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, actions) => {
      try {
        onSubmit(values);
        actions.setSubmitting(false);
      } catch (error) {
        console.error('Error al enviar el formulario:', error);
      }
    },
  });

  return formik;
};

export default useFormikEntryFilters;
