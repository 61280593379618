import React from 'react';
import { Button, Row, Col, Alert } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import { CustomTable, GeneralContainer } from '../../../../components';
import { ShareResponse } from '../../../../share';
import SearchRow from '../components/Filters';
import { TransfersResponse } from '../../types/transfers-response';
import { TransferStatus } from '../../types/transfer-status';

interface TransfersViewProps {
  data?: TransfersResponse;
  onViewCreate: () => void;
  onPagination: (page: number, pageSize: number) => void;
  onViewDetail: (id: string) => void;
  formik: any;
  states?: ShareResponse[];
  isLoading: boolean;
  onResetFilters: () => void;
}

const TransfersView: React.FC<TransfersViewProps> = ({
  data,
  onViewCreate,
  onViewDetail,
  onPagination,
  formik,
  states,
  isLoading,
  onResetFilters,
}) => {
  const columns = [
    {
      title: 'Número ticket',
      dataIndex: 'ticket',
      key: 'ticket',
      sorter: true,
      align: 'center',
    },
    {
      title: 'Tipo ticket',
      dataIndex: 'ticket_type',
      key: 'ticket_type',
      sorter: true,
      align: 'center',
      responsive: ['lg'],
    },
    {
      title: 'Fecha de solicitud',
      dataIndex: 'query_date',
      key: 'query_date',
      sorter: true,
      align: 'center',
      render: (date: string) =>
        date ? new Date(date).toLocaleDateString('es-ES') : 'Sin fecha',
    },
    {
      title: 'Estado solicitud',
      dataIndex: 'status',
      key: 'status',
      sorter: true, align: 'center',
      render: (status: string) => {
        const statusTypeMap: Record<
          TransferStatus,
          'success' | 'info' | 'warning' | 'error'
        > = {
          [TransferStatus.NUEVO]: 'info',
          [TransferStatus.TRAMITE_INGRESO]: 'warning',
          [TransferStatus.DEVUELTO]: 'error',
          [TransferStatus.ENVIADO]: 'info',
          [TransferStatus.FINALIZADO]: 'success',
        };

        const type = statusTypeMap[status as TransferStatus] || 'info';
        const message = status || 'Desconocido';

        return <Alert message={message} type={type} showIcon />;
      },
    },
    {
      title: 'Solicitante',
      dataIndex: 'requester',
      key: 'requester',
      sorter: true,
      align: 'center',
    },
    {
      title: 'Nuevo responsable',
      dataIndex: 'new_responsible',
      key: 'new_responsible',
      sorter: true,
      align: 'center',
    },
    {
      title: 'Observaciones',
      dataIndex: 'observations',
      key: 'observations',
      align: 'center',
      render: (text: string) => (text ? text : 'Sin observaciones'),
    },
    {
      title: 'Acciones',
      key: 'actions',
      align: 'center',
      render: (_: any, record: { id: number }) => (
        <Button
          type="link"
          icon={<EyeOutlined />}
          onClick={() => onViewDetail(record.id.toString())}
        />
      ),
    },
  ];

  return (
    <GeneralContainer
      title="Solicitudes de traslado entre funcionarios"
      actionLabel="Crear solicitud"
      onActionClick={onViewCreate}
      isLoading={isLoading}
    >
      {/* Filtros */}
      <Row gutter={[16, 16]} style={{ marginBottom: '16px' }}>
        <Col xs={24}>
          <SearchRow
            formik={formik}
            states={states}
            onCancel={onResetFilters}
            isLoading={isLoading}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={24}>
          <CustomTable
            loading={isLoading}
            columns={columns as any}
            dataSource={data?.data?.map((refund) => ({
              ...refund,
              id: Number(refund.id) || -1,
            }))}
            paginationConfig={{
              current: data?.current_page,
              pageSize: data?.per_page,
              total: data?.total,
              onChange: onPagination,
            }}
            scroll={{ x: 'max-content' }}
          />
        </Col>
      </Row>
    </GeneralContainer>
  );
};

export default TransfersView;
