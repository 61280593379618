import React from 'react';
import { Typography } from 'antd';
import classNames from 'classnames';

const { Text } = Typography;

type FontSizeType = 'small' | 'middle' | 'big';

interface CustomTextProps {
  text: string;
  strong?: boolean;
  inline?: boolean;
  fontSize?: FontSizeType;
  centered?: boolean; // Nuevo prop para centrar
}

const CustomText: React.FC<CustomTextProps> = ({
  text,
  strong,
  inline,
  fontSize = 'middle',
  centered = false,
}) => {
  const computedFontSize = (() => {
    switch (fontSize) {
      case 'small':
        return '14px';
      case 'big':
        return '18px';
      case 'middle':
      default:
        return '16px';
    }
  })();

  return (
    <Text
      className={classNames('custom-label', {
        'custom-text-inline': inline,
        'custom-text-block': !inline,
      })}
      strong={strong}
      style={{
        fontSize: computedFontSize,
        textAlign: centered ? 'center' : undefined,
        display: inline ? 'inline' : 'block',
      }}
    >
      {text}
    </Text>
  );
};

export default CustomText;
