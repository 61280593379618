import React from "react";
import DashboardView from "../Views/DashboardView";

const DashboardContainer: React.FC = () => {
  return (
    <DashboardView message="¡Bienvenido al sistema de gestión de activos!" />
  );
};

export default DashboardContainer;
