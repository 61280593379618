import { QueryKey } from '@tanstack/react-query';
import apiClient from '../../../services/apiClient';
import {
  PetitionsFilter,
  PetitionsRequest,
  PetitionsResponse,
} from '../types/petitions-response';
import { TransformFilters } from '../../../utils';

export const PetitionsReqAPI = {
  gets: async ({
    queryKey,
  }: {
    queryKey: QueryKey;
  }): Promise<PetitionsResponse> => {
    const [, filters] = queryKey as [string, PetitionsFilter?];
    const transformedFilters = TransformFilters(filters);
    const response = await apiClient.get('/api/petition', {
      params: {
        ...transformedFilters,
        paginate: true,
        page: filters?.page,
      },
    });
    return response.data;
  },
  get: async (id: string): Promise<PetitionsRequest> => {
    const response = await apiClient.get(`/api/petition/${id}`);
    return response.data;
  },
  put: async (
    id: string,
    data: Record<string, PetitionsRequest>
  ): Promise<void> => {
    const response = await apiClient.put(`/api/petition/${id}`, data);
    return response.data;
  },
  post: async (req: PetitionsRequest): Promise<void> => {
    delete req.id;
    const response = await apiClient.post(
      '/api/petition',
      {
        ...req,
        documents: req?.documents?.map((doc) => doc?.file) || [],
      },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return response.data;
  },
  delete: async (id: string): Promise<void> => {
    const response = await apiClient.delete(`/api/petition/${id}`);
    return response.data;
  },
  export: async (ids: string[]): Promise<void> => {
    const params = ids.map((id) => `ids[]=${id}`).join('&');
    const response = await apiClient.get(`/api/petition/export?${params}`, {
      responseType: 'blob',
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'exported_petitions.xlsx');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    return response.data;
  },
};
