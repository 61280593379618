import React from 'react';
import { CustomModal } from '../../../../components';
import { GoodsRequest } from '../../types/goods-response';
import GoodsDetailView from '../Views/GoodsDetailView';
import { useExportRefund } from '../../../../hooks';

interface GoodsDetailProps {
  isOpen: boolean;
  OnClose: () => void;
  data?: GoodsRequest;
  isLoading: boolean;
}

const GoodsDetailContainer: React.FC<GoodsDetailProps> = ({
  isOpen,
  OnClose,
  data,
  isLoading,
}) => {
  const [footerLeft, setFooterLeft] = React.useState<React.ReactNode>(null);
  const { mutateAsync } = useExportRefund();

  return (
    <CustomModal
      title={`Detalle solicitud de entrada - ${data?.ticket || ''}`}
      open={isOpen}
      onCancel={OnClose}
      width={800}
      isLoading={isLoading}
      footerLeft={footerLeft}
    >
      <GoodsDetailView
        detailData={data}
        setFooterLeft={setFooterLeft}
        onDocuments={mutateAsync}
      />
    </CustomModal>
  );
};

export default GoodsDetailContainer;
