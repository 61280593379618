import React from 'react';
import GoodsCreateView from '../Views/GoodsCreateView';
import { CustomModal } from '../../../../components';
import { useCreateGoods } from '../../../../hooks';
import { UploadedFile, useGoodsRequestFormik } from '../../../../utils';
import { GoodsRequest } from '../../types';
import { useAtom } from 'jotai';
import { authAtom } from '../../../../state';
import {
  useDeliveryOptions,
  useDependecies,
  useEntryStates,
  useEntyTypes,
  useGoodStates,
  useItem,
  useItemTypes,
  useLocation,
  useSedes,
  useSupervisors,
} from '../../../../share';
import { ItemAdd, ItemResponse } from '../../../../share/types/item-response';

interface GoodsCreateProps {
  isOpen: boolean;
  OnClose: () => void;
}

const GoodsCreateContainer: React.FC<GoodsCreateProps> = ({
  isOpen,
  OnClose,
}) => {
  const [files, setFiles] = React.useState<UploadedFile[]>();
  const [items, setItems] = React.useState<ItemAdd[]>();
  const [authData] = useAtom(authAtom);
  const { mutateAsync, isPending: loadCreate } = useCreateGoods();
  const { data: entyTypes } = useEntyTypes();
  const { data: itemTypes } = useItemTypes();
  const { data: entryStates } = useEntryStates();
  const { data: goodStates } = useGoodStates();
  const { data: entryDevelivery } = useDeliveryOptions();
  const { data: location } = useLocation();

  const { data: sedes } = useSedes();
  const { data: supervisors } = useSupervisors();
  const { data: dependencies } = useDependecies();
  const { data: listItems } = useItem();

  const onSave = async (values: GoodsRequest) => {
    console.log('items', items);

    await mutateAsync({
      ...values,
      goods_location_id: values.location,
      documents: files,
      request_type: 'Solicitud de bienes',

      items: items?.map((x) => ({
        item_id: x.id,
        quantity_requested: x.quantity,
        status_id: '3',
        observations: x.observation,
      })),
    });
    onReset();
  };

  const formik = useGoodsRequestFormik({ onSubmit: onSave });

  const handleSubmit = () => {
    formik.handleSubmit();
  };

  const onReset = () => {
    formik.resetForm();
    OnClose();
    setItems([]);
    setFiles([]);
  };

  const handleAddItem = (item: ItemResponse) => {
    onResetItem();
    setItems((prev) => [
      ...(prev || []),
      {
        name: item.name,
        id: item.id.toString(),
        observation: formik.values.observations,
        quantity: 1,
      },
    ]);
  };

  const onResetItem = () => {
    formik.setFieldValue('item_id', '');
    formik.setFieldValue('item_quantity', '');
    formik.setFieldValue('observations', '');
  };

  const handleDeleteItem = (req: number) => {
    const updatedItems = items?.filter((_, index) => index !== req);
    setItems(updatedItems);
    onResetItem();
  };

  return (
    <CustomModal
      title="Creación de solicitud de Bienes"
      labelSubmit="Crear Solicitud"
      open={isOpen}
      onCancel={() => {
        onReset();
      }}
      width={800}
      isLoadingSave={loadCreate}
      onSave={handleSubmit}
      onClose={() => {
        onReset();
      }}
    >
      <GoodsCreateView
        entyTypes={entyTypes}
        location={location}
        itemsAdded={items}
        items={listItems}
        itemTypes={itemTypes}
        entryDevelivery={entryDevelivery}
        entryStates={entryStates}
        goodStates={goodStates}
        authData={authData}
        sedes={sedes}
        supervisors={supervisors}
        dependencies={dependencies}
        formik={formik}
        onSave={handleSubmit}
        onCancel={onReset}
        onFiles={setFiles}
        files={files}
        onAdd={handleAddItem}
        onDeleteItem={handleDeleteItem}
      />
    </CustomModal>
  );
};

export default GoodsCreateContainer;
