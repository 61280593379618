import React from 'react';
import EntryRequestDetailView from '../Views/EntryRequestDetailView';
import { CustomModal } from '../../../../components';
import { EntryRequest } from '../../types/entry-response';
import { useExportEntries } from '../../../../hooks/entryRequests';

interface EntryRequestsDetailProps {
  isOpen: boolean;
  OnClose: () => void;
  data?: EntryRequest;
  isLoading: boolean;
}

const EntryRequestDetailContainer: React.FC<EntryRequestsDetailProps> = ({
  isOpen,
  OnClose,
  data,
  isLoading,
}) => {
  const [footerLeft, setFooterLeft] = React.useState<React.ReactNode>(null);
  const { mutateAsync } = useExportEntries();

  return (
    <CustomModal
      title={`Detalle solicitud de entrada - ${data?.invoice_number || ''}`}
      open={isOpen}
      onCancel={OnClose}
      width={800}
      isLoading={isLoading}
      footerLeft={footerLeft}
    >
      <EntryRequestDetailView
        detailData={data}
        setFooterLeft={setFooterLeft}
        onDocuments={mutateAsync}
      />
    </CustomModal>
  );
};

export default EntryRequestDetailContainer;
