import React from 'react';
import { Form, Input } from 'antd';

interface FormInputProps {
  label: string;
  name: string;
  placeholder?: string;
  value: string | undefined;
  error?: string | undefined;
  touched?: boolean | undefined;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  type?: 'text' | 'number';
  isPassword?: boolean;
  disabled?: boolean;
}

const FormInput: React.FC<FormInputProps> = ({
  label,
  name,
  placeholder,
  value,
  error,
  touched,
  onChange,
  onBlur,
  isPassword,
  type = 'text',
  disabled = false,
}) => (
  <Form.Item
    label={<span className="custom-label">{label}</span>}
    validateStatus={touched && error ? 'error' : undefined}
    help={touched && error}
    labelCol={{ span: 24 }}
    className="custom-form-item"
  >
    {isPassword ? (
      <Input.Password
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        size="middle"
        disabled={disabled}
      />
    ) : (
      <Input
        name={name}
        placeholder={placeholder}
        value={value}
        type={type === 'text' ? 'text' : 'number'}
        onChange={onChange}
        onBlur={onBlur}
        size="middle"
        disabled={disabled}
      />
    )}
  </Form.Item>
);

export default FormInput;
