import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { EntryReqAPI } from '../../modules/EntryRequests/api/EntryReqAPI';
import {
  EntryFilter,
  EntryRequestResponse,
} from '../../modules/EntryRequests/types/entry-response';
import { useCustomToast } from '../../components';

const keyQuery = ['entryRequests'] as const;

export const useFetchEntryReq = (filters?: Partial<EntryFilter>) => {
  return useQuery<EntryRequestResponse, Error>({
    queryKey: [keyQuery, filters || {}],
    queryFn: ({ queryKey }) => EntryReqAPI.gets({ queryKey }),
    enabled: true,
  });
};

export const useFetchEntryReqById = (id?: string) => {
  return useQuery({
    queryKey: [...keyQuery, id],
    queryFn: () => (id ? EntryReqAPI.get(id) : Promise.resolve(undefined)),
    enabled: !!id,
  });
};

export const useCreateEntryReq = () => {
  const { showToast } = useCustomToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: EntryReqAPI.post,
    onSuccess: () => {
      showToast('success', 'Entry Created');
      queryClient.invalidateQueries({ queryKey: [keyQuery, {}] });
    },
  });
};

export const useUpdateEntryReq = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id, data }: { id: string; data: any }) =>
      EntryReqAPI.put(id, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [keyQuery, {}] });
    },
  });
};

export const useDeleteEntryReq = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: string) => EntryReqAPI.delete(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [keyQuery, {}] });
    },
  });
};

export const useExportEntries = () => {
  const { showToast } = useCustomToast();
  return useMutation({
    mutationFn: (ids: string[]) => EntryReqAPI.export(ids),
    onSuccess: () => {
      showToast('success', 'Export successful');
    },
    onError: () => {
      showToast('error', 'Failed to export entries');
    },
  });
};
