import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import MainLayout from '../layouts/MainLayout';
import DashboardContainer from '../modules/Dashboard/components/Containers/DashboardContainer';
import { useAtomValue } from 'jotai';
import { authAtom } from '../state/authAtoms';
import EntryRequestsContainer from '../modules/EntryRequests/components/Containers/EntryRequestsContainer';
import GoodsContainer from '../modules/Goods/components/Containers/GoodsContainer';
import RefundsContainer from "../modules/Refunds/components/Containers/RefundsContainer";
import PetitionsContainer from '../modules/Petitions/components/Containers/PetitionsContainer';
import TransfersContainer from '../modules/Transfers/components/Containers/TransfersContainer';

// Ruta privada
const PrivateRoute = ({ children }: { children: React.ReactNode }) => {
  const req = useAtomValue(authAtom);
  return req?.authorization?.token ? <>{children}</> : <Navigate to="/login" />;
};

const PrivateRoutes: React.FC = () => {
  return (
    <Routes>
      <Route element={<MainLayout />}>
        <Route
          path="/"
          element={
            <PrivateRoute>
              <DashboardContainer />
            </PrivateRoute>
          }
        />
        <Route
          path="/myrequests"
          element={
            <PrivateRoute>
              <PetitionsContainer />
            </PrivateRoute>
          }
        />
        {/* ENTRY REQUEST PATH*/}
        <Route
          path="/entryrequests"
          element={
            <PrivateRoute>
              <EntryRequestsContainer />
            </PrivateRoute>
          }
        />
        <Route
          path="/goodsrequests"
          element={
            <PrivateRoute>
              <GoodsContainer />
            </PrivateRoute>
          }
        />
        <Route
          path="/refunds"
          element={
            <PrivateRoute>
              <RefundsContainer />
            </PrivateRoute>
          }
        />
        <Route
          path="/transfers"
          element={
            <PrivateRoute>
              <TransfersContainer />
            </PrivateRoute>
          }
        />
      </Route>
    </Routes>
  );
};

export default PrivateRoutes;
