import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { GoodsFilter, GoodsResponse } from '../../modules/Goods/types';
import { GoodsReqAPI } from '../../modules/Goods/api';
import { useCustomToast } from '../../components';

// Query key for caching
const keyQuery = ['goods'] as const;

export const useFetchGoodsRequests = (filters?: Partial<GoodsFilter>) => {
  return useQuery<GoodsResponse, Error>({
    queryKey: [keyQuery, filters || {}],
    queryFn: ({ queryKey }) => GoodsReqAPI.gets({ queryKey }),
    enabled: true,
  });
};

// Fetch a single goods request by ID
export const useFetchGoodsById = (id?: string) => {
  return useQuery({
    queryKey: [...keyQuery, id],
    queryFn: () => (id ? GoodsReqAPI.get(id) : Promise.resolve(undefined)),
    enabled: !!id,
  });
};

// Create a new goods request
export const useCreateGoods = () => {
  const { showToast } = useCustomToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: GoodsReqAPI.post,
    onSuccess: () => {
      showToast('success', 'Good Created');
      queryClient.invalidateQueries({ queryKey: [keyQuery, {}] });
    },
  });
};

// Delete a goods request
export const useDeleteGoods = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: string) => GoodsReqAPI.delete(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: keyQuery });
    },
  });
};

//Export excel

export const useExportGoods = () => {
  const { showToast } = useCustomToast();
  return useMutation({
    mutationFn: (ids: string[]) => GoodsReqAPI.export(ids),
    onSuccess: () => {
      showToast('success', 'Export successful');
    },
    onError: () => {
      showToast('error', 'Failed to export');
    },
  });
};
