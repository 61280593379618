import React, { Fragment } from 'react';
import { Button, Col, Divider, Row, Typography } from 'antd';
import { UploadedFile } from '../../../../utils';
import { AuthResponse } from '../../../Auth/types';
import { ShareResponse } from '../../../../share';
import { CustomButton, CustomTable, FormInput, FormSelect, UploadWithTable } from '../../../../components';
import { ColumnsType } from 'antd/es/table';
import { AssetsResponse } from '../../../../share/types/asset-response';
import { PlusOutlined } from '@ant-design/icons';
import { LocationResponse } from '../../../../share/types/location-response';

const { Title } = Typography;

interface TransferCreateViewProps {
    formik: any;
    entyTypes?: ShareResponse[];
    itemTypes?: ShareResponse[];
    entryStates?: ShareResponse[];
    goodStates?: ShareResponse[];
    entryDevelivery?: ShareResponse[];
    sedes?: ShareResponse[];
    dependencies?: ShareResponse[];
    supervisors?: ShareResponse[];
    onSave: () => void;
    onCancel: () => void;
    authData?: AuthResponse | null;
    onFiles: (files: UploadedFile[]) => void;
    files?: UploadedFile[];
    assets?: AssetsResponse[];
    location?: LocationResponse[];
    assetAdded?: AssetsResponse[];
    onAdd?: (asset: AssetsResponse) => void;
    onDeleteAsset: (asset: number) => void;
}

const TransferCreateView: React.FC<TransferCreateViewProps> = ({
    formik,
    goodStates,
    dependencies,
    sedes,
    supervisors,
    authData,
    files,
    onFiles,
    assets,
    location,
    assetAdded,
    onAdd,
    onDeleteAsset,
}) => {
    
    let assetSelected: AssetsResponse = {
        asset_class: '',
        item: '',
        name: '',
        plate_code: '',
        status: ''
    };

    function onSetAsset(assetId: string) {
        assetSelected = assets?.find((value) => value.item === assetId) as AssetsResponse;

        formik.setFieldValue('plate_code', assetSelected?.plate_code);
        formik.setFieldValue('asset_class', assetSelected?.asset_class);
        formik.setFieldValue('name', assetSelected?.name);
    }

    const columns: ColumnsType<AssetsResponse> = [
        {
          title: 'Item',
          dataIndex: 'item',
          key: 'item',
        },
        {
          title: 'Codigo placa',
          dataIndex: 'plate_code',
          key: 'plate_code',
        },
        {
          title: 'Tipo clase',
          dataIndex: 'asset_class',
          key: 'asset_class',
        },
        {
          title: 'Artículo',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: 'Acciones',
          key: 'actions',
          render: (_, record, index) => (
            <Button danger onClick={() => onDeleteAsset(index)}>
              Eliminar
            </Button>
          ),
        },
      ];

    return (
        <Fragment>
            <Row gutter={[16, 16]} style={{ marginBottom: '20px' }}>
                <Col xs={24} sm={12} md={8} lg={6}>
                    <FormInput
                        label="Solicitante"
                        name="requester_id"
                        placeholder="Solicitante"
                        disabled={true}
                        value={`${authData?.user.name} ${authData?.user.last_name}`}
                        error={formik.errors.requester_id}
                        touched={formik.touched.requester_id}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                    <FormInput
                        label="Fecha"
                        name="query_date"
                        placeholder="Solicitante"
                        value={formik.values.query_date}
                        disabled
                        error={formik.errors.query_date}
                        touched={formik.touched.query_date}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                    <FormSelect
                        label="Dependencia"
                        name="dependency_id"
                        placeholder="Seleccione"
                        value={formik.values.dependency_id}
                        options={dependencies}
                        error={formik.errors.dependency_id}
                        touched={formik.touched.dependency_id}
                        onChange={(value) => formik.setFieldValue('dependency_id', value)}
                        onBlur={formik.handleBlur}
                    />
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                    <FormSelect
                        label="Nueva dependencia"
                        name="new_dependency_id"
                        placeholder="Seleccione"
                        value={formik.values.new_dependency_id}
                        options={dependencies}
                        error={formik.errors.new_dependency_id}
                        touched={formik.touched.new_dependency_id}
                        onChange={(value) => formik.setFieldValue('new_dependency_id', value)}
                        onBlur={formik.handleBlur}
                    />
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                    <FormSelect
                        label="Nuevo responsable"
                        name="new_responsible_id"
                        placeholder="Seleccione"
                        value={formik.values.new_responsible_id}
                        options={supervisors}
                        error={formik.errors.new_responsible_id}
                        touched={formik.touched.new_responsible_id}
                        onChange={(value) => formik.setFieldValue('new_responsible_id', value)}
                        onBlur={formik.handleBlur}
                    />
                </Col>
            </Row>
            <Divider />
            <Row gutter={[16, 16]} style={{ marginBottom: '20px' }}>
                <Col xs={24} sm={12} md={8} lg={6}>
                    <FormSelect
                        label="Activo"
                        name="item"
                        placeholder="Seleccione"
                        value={formik.values.item}
                        options={assets?.map((x) => ({
                            name: x.name,
                            id: x.item.toString(),
                          }))}
                        error={formik.errors.item}
                        touched={formik.touched.item}
                        onChange={(value) => onSetAsset(value)}
                        onBlur={formik.handleBlur}
                    />
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                    <FormInput
                        label="Código placa"
                        name="plate_code"
                        placeholder="Inserte"
                        value={formik.values.plate_code}
                        error={formik.errors.plate_code}
                        touched={formik.touched.plate_code}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                    <FormInput
                        label="Tipo clase"
                        name="asset_class"
                        placeholder="Inserte"
                        value={formik.values.asset_class}
                        error={formik.errors.asset_class}
                        touched={formik.touched.asset_class}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                    <FormInput
                        label="Artículo"
                        name="name"
                        placeholder="Inserte"
                        value={formik.values.name}
                        error={formik.errors.name}
                        touched={formik.touched.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                </Col>
            </Row>
            <Row gutter={[16, 16]} justify="end">
          <Col xs={24} sm={24} md={24} lg={24}>
            <CustomButton
              onClick={() => {
                if (assetSelected?.item && onAdd) {
                  onAdd(assetSelected);
                }
              }}
              icon={<PlusOutlined />}
              label="Añadir"
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={24}>
            <CustomTable<AssetsResponse>
              dataSource={assetAdded}
              columns={columns}
              rowKey="id"
              pagination={{
                pageSize: 5,
              }}
            />
          </Col>
        </Row>
            <Divider />
            <Title level={4} style={{ textAlign: 'center', marginBottom: '20px' }}>
                Documentos cargados
            </Title>
            <Row>
                <Col xs={24}>
                <UploadWithTable onFilesChange={onFiles} files={files} />
                </Col>
            </Row>
        </Fragment>
    );
};

export default TransferCreateView;
