import { useMutation } from '@tanstack/react-query';
import { useSetAtom } from 'jotai';
import { authAtom } from '../../state';
import { AuthTransfersAPI } from '../../modules/Auth/api/AuthTransfersAPI';
import { AuthResponse } from '../../modules/Auth/types';
import { useCustomToast } from '../../components';

export const useLogin = () => {
  const setAuth = useSetAtom(authAtom);
  const { showToast } = useCustomToast();

  return useMutation({
    mutationKey: ['login'],
    mutationFn: (credentials: { email: string; password: string }) =>
      AuthTransfersAPI.login(credentials),
    onSuccess: (req: AuthResponse) => {
      setAuth(req);
    },
    onError: () => {
      showToast('error', 'Invalid email or password. Please try again.');
    },
  });
};

export const useLogout = () => {
  const setAuth = useSetAtom(authAtom);
  return useMutation({
    mutationKey: ['logout'],
    mutationFn: () => AuthTransfersAPI.logout(),
    onSuccess: () => {
      setAuth(null);
    },
  });
};
