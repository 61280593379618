import * as Yup from 'yup';
import { useFormik } from 'formik';
import { InventoryRequest } from '../../../modules/Inventory/types';
export const inventoryRequestValidationSchema = Yup.object({
  id: Yup.string(),
  observation: Yup.string(),
});

interface UseInventoryRequestFormikProps {
  onSubmit: (values: InventoryRequest) => void;
}

const useInventoryRequestFormik = ({
  onSubmit,
}: UseInventoryRequestFormikProps) => {
  return useFormik<InventoryRequest>({
    initialValues: {
      id: '',
      items: [],
    },
    validationSchema: inventoryRequestValidationSchema,
    onSubmit,
  });
};

export default useInventoryRequestFormik;
