import React from 'react';
import { Table, TableProps, PaginationProps } from 'antd';

interface CustomTableProps<T> extends TableProps<T> {
  dataSource?: T[];
  paginationConfig?: PaginationProps;
}

const CustomTable = <T extends object>({
  dataSource,
  columns,
  paginationConfig,
  ...rest
}: CustomTableProps<T>) => {
  return (
    <Table<T>
      columns={columns}
      dataSource={dataSource}
      className="table-container"
      size={'middle'}
      bordered={false}
      rowClassName={() => 'custom-row-class'}
      pagination={{
        ...paginationConfig,
        showSizeChanger: false,
      }}
      {...rest}
    />
  );
};

export default CustomTable;
