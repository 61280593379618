import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import AuthLayout from "../layouts/AuthLayout";
import AuthContainer from "../modules/Auth/components/Containers/AuthContainer";
import { useAtomValue } from "jotai";
import { authAtom } from "../state/authAtoms";

const PublicRoute = ({ children }: { children: React.ReactNode }) => {
  const req = useAtomValue(authAtom);
  return req?.authorization?.token ? <Navigate to="/" /> : <>{children}</>;
};

const PublicRoutes: React.FC = () => {
  return (
    <Routes>
      <Route element={<AuthLayout />}>
        <Route
          path="/login"
          element={
            <PublicRoute>
              <AuthContainer />
            </PublicRoute>
          }
        />
      </Route>
    </Routes>
  );
};

export default PublicRoutes;
