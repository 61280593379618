import React, { Fragment } from 'react';
import { Row, Col, Typography, Divider, Button } from 'antd';
import {
  CustomButton,
  CustomTable,
  CustomText,
  CustomTextArea,
  FormInput,
  FormSelect,
  UploadWithTable,
} from '../../../../components';
import { UploadedFile } from '../../../../utils';
import { AuthResponse } from '../../../Auth/types';
import { ShareResponse } from '../../../../share';
import { DateTime } from 'luxon';
import { LocationResponse } from '../../../../share/types/location-response';
import { ItemAdd, ItemResponse } from '../../../../share/types/item-response';
import { PlusOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { DeleteOutlined } from '@ant-design/icons';

const { Title } = Typography;

interface GoodsCreateViewProps {
  formik: any;
  entyTypes?: ShareResponse[];
  itemTypes?: ShareResponse[];
  entryStates?: ShareResponse[];
  goodStates?: ShareResponse[];
  entryDevelivery?: ShareResponse[];
  sedes?: ShareResponse[];
  dependencies?: ShareResponse[];
  supervisors?: ShareResponse[];
  onSave: () => void;
  onCancel: () => void;
  authData?: AuthResponse | null;
  onFiles: (files: UploadedFile[]) => void;
  files?: UploadedFile[];
  location?: LocationResponse[];
  //ITEMS
  items?: ItemResponse[];
  itemsAdded?: ItemAdd[];
  onAdd?: (item: ItemResponse) => void;
  onDeleteItem: (item: number) => void;
}

const GoodsCreateView: React.FC<GoodsCreateViewProps> = ({
  formik,
  itemTypes,
  authData,
  files,
  //goods
  goodStates,
  dependencies,
  location,
  items,
  itemsAdded,
  sedes,
  onAdd,
  onDeleteItem,
  onFiles,
}) => {
  const columns: ColumnsType<ItemAdd> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Cantidad',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: 'Observaciones',
      dataIndex: 'observation',
      key: 'observation',
      render: (text) => (text ? text : 'N/A'),
    },
    {
      title: 'Acciones',
      key: 'actions',
      render: (_, record, index) => (
        <Button
          danger
          icon={<DeleteOutlined />}
          onClick={() => onDeleteItem(index)}
        />
      ),
    },
  ];

  return (
    <>
      <Row gutter={[16, 16]} style={{ marginBottom: '20px' }}>
        <Col xs={24} sm={12} md={8} lg={6}>
          <FormInput
            label="Solicitante"
            name="requester_id"
            placeholder="Solicitante"
            value={`${authData?.user.name} ${authData?.user.last_name}`}
            disabled
            error={formik.errors.requester_id}
            touched={formik.touched.requester_id}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Col>

        <Col xs={24} sm={12} md={8} lg={6}>
          <FormInput
            label="Fecha"
            name="initial_date"
            placeholder="Solicitante"
            value={DateTime.now().toFormat('yyyy-MM-dd')}
            disabled
            error={formik.errors.initial_date}
            touched={formik.touched.initial_date}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Col>

        <Col xs={24} sm={12} md={8} lg={6}>
          <FormSelect
            label="Dependencia"
            name="dependency"
            placeholder="Seleccione la dependencia"
            value={formik.values.dependency_id}
            options={dependencies}
            error={formik.errors.dependency_id}
            touched={formik.touched.dependency_id}
            onChange={(value) => formik.setFieldValue('dependency_id', value)}
            onBlur={formik.handleBlur}
          />
        </Col>

        <Col xs={24} sm={12} md={8} lg={6}>
          <FormSelect
            label="Ubicación"
            name="location_id"
            placeholder="Seleccione"
            value={formik.values.location_id}
            // options={sedes}
            options={location?.map((x) => ({
              name: x.name,
              id: x.id.toString(),
            }))}
            error={formik.errors.location_id}
            touched={formik.touched.location_id}
            onChange={(value) => formik.setFieldValue('location_id', value)}
            onBlur={formik.handleBlur}
          />
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        {/* PRUEBA REQUEST TYPE */}
        <Col xs={24} sm={12} md={8} lg={6}>
          <FormSelect
            label="Tipo de Solicitud"
            name="goods_type"
            placeholder="Seleccione el tipo de solicitud"
            value={formik.values.goods_type}
            options={itemTypes}
            error={formik.errors.goods_type}
            touched={formik.touched.goods_type}
            onChange={(value) => formik.setFieldValue('goods_type', value)}
            onBlur={formik.handleBlur}
          />
        </Col>

        <Col xs={24} sm={12} md={8} lg={6}>
          <FormSelect
            label="Estado solicitud"
            name="status_id"
            placeholder="Seleccione"
            value={formik.values.status_id}
            options={goodStates}
            error={formik.errors.status_id}
            touched={formik.touched.status_id}
            onChange={(value) => formik.setFieldValue('status_id', value)}
            onBlur={formik.handleBlur}
          />
        </Col>
      </Row>
      <Divider />

      {/* LIST ITEMS  */}
      <Fragment>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} md={8} lg={8}>
            <FormSelect
              label="Bien de consumo"
              name="item_id"
              placeholder="Seleccione"
              value={formik.values.item_id}
              options={items?.map((x) => ({
                name: x.name,
                id: x.id.toString(),
              }))}
              error={formik.errors.item_id}
              touched={formik.touched.item_id}
              onChange={(value) => formik.setFieldValue('item_id', value)}
              onBlur={formik.handleBlur}
            />
          </Col>
          <Col xs={24} sm={12} md={8} lg={8}>
            <FormInput
              label="Cantidad"
              type="number"
              name="item_quantity"
              placeholder="Seleccione"
              value={formik.values.item_quantity}
              error={formik.errors.item_quantity}
              touched={formik.touched.item_quantity}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24} lg={24}>
            <CustomTextArea
              label="Observaciones"
              name="observations"
              placeholder="Seleccione"
              value={formik.values.observations}
              error={formik.errors.observations}
              touched={formik.touched.observations}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]} justify="end">
          <Col>
            <CustomButton
              onClick={() => {
                const selectedItem = items?.find(
                  (item) => item.id.toString() === formik.values.item_id
                );
                if (selectedItem && onAdd) {
                  onAdd(selectedItem);
                }
              }}
              icon={<PlusOutlined />}
              label="Añadir"
              disabled={
                !formik.values.item_id ||
                !formik.values.item_quantity ||
                !formik.values.observations
              }
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]} className="mt-8">
          <Col xs={24}>
            {itemsAdded && itemsAdded.length > 0 ? (
              <CustomTable<ItemAdd>
                dataSource={itemsAdded}
                columns={columns}
                rowKey="id"
                pagination={{
                  pageSize: 5,
                }}
              />
            ) : (
              <CustomText
                text="No hay Bien de consumo añadidos, por favor agregue uno"
                strong
                centered
                fontSize="small"
              />
            )}
          </Col>
        </Row>
      </Fragment>

      <Divider />
      <Title level={4} style={{ textAlign: 'center', marginBottom: '20px' }}>
        Documentos cargados
      </Title>
      <Row>
        <Col xs={24}>
          <UploadWithTable onFilesChange={onFiles} files={files} />
        </Col>
      </Row>
    </>
  );
};

export default GoodsCreateView;
