import React from 'react';
import { CustomModal } from '../../../../components';
import TransfersDetailView from '../Views/TransfersDetailView';
import { TransfersRequest } from '../../types/transfers-response';
import { useExportTransfer } from '../../../../hooks';

interface TransfersDetailProps {
  isOpen: boolean;
  OnClose: () => void;
  data?: TransfersRequest;
  isLoading: boolean;
}

const TransfersDetailContainer: React.FC<TransfersDetailProps> = ({
  isOpen,
  OnClose,
  data,
  isLoading,
}) => {
  const [footerLeft, setFooterLeft] = React.useState<React.ReactNode>(null);
  const { mutateAsync } = useExportTransfer();

  return (
    <CustomModal
      title={`Detalle traslados entre funcionarios - ${data?.ticket || ''}`}
      open={isOpen}
      onCancel={OnClose}
      width={800}
      isLoading={isLoading}
      footerLeft={footerLeft}
    >
      <TransfersDetailView
        detailData={data}
        setFooterLeft={setFooterLeft}
        onDocuments={mutateAsync}
      />
    </CustomModal>
  );
};

export default TransfersDetailContainer;
