import React, { Fragment } from 'react';
import { Row, Col, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { CustomButton, FormInput, FormSelect } from '../../../../components';
import { ShareResponse } from '../../../../share';

interface SearchRowProps {
  formik: any;
  states?: ShareResponse[];
  onCancel: () => void;
  isLoading?: boolean;
  supervisors?: ShareResponse[];
}

const SearchRow: React.FC<SearchRowProps> = ({
  formik,
  states,
  onCancel,
  isLoading,
  supervisors,
}) => {
  return (
    <Fragment>
      <Row gutter={[16, 16]} className="container-filters">
        <Col xs={24} sm={12} md={8}>
          <FormInput
            label="Número de Ticket"
            name="ticket"
            placeholder="Ingrese Número de Ticket"
            value={formik.values.ticket}
            error={formik.errors.ticket}
            touched={formik.touched.ticket}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Col>
        <Col xs={24} sm={12} md={8}>
          <FormSelect
            label="Solicitante"
            name="requester_id"
            placeholder="Seleccione"
            value={formik.values.requester_id}
            options={supervisors}
            error={formik.errors.requester_id}
            touched={formik.touched.requester_id}
            onChange={(value) => formik.setFieldValue('requester_id', value)}
            onBlur={formik.handleBlur}
          />
        </Col>
        <Col xs={24} sm={12} md={8}>
          <FormSelect
            label="Estado de la Solicitud"
            name="status_id"
            placeholder="Seleccione Estado"
            value={formik.values.status_id}
            options={states}
            error={formik.errors.status_id}
            touched={formik.touched.status_id}
            onChange={(value) => formik.setFieldValue('status_id', value)}
            onBlur={formik.handleBlur}
          />
        </Col>
      </Row>

      <Row className="filters-buttons">
        <Col xs={24} sm={24} md={6}>
          <Space>
            <CustomButton
              buttonType="secondary"
              label="Limpiar"
              onClick={() => onCancel()}
              icon={<SearchOutlined />}
              size="middle"
              disabled={isLoading}
            />
            <CustomButton
              label="Buscar"
              onClick={formik.handleSubmit}
              icon={<SearchOutlined />}
              size="middle"
              disabled={isLoading}
            />
          </Space>
        </Col>
      </Row>
    </Fragment>
  );
};

export default SearchRow;
