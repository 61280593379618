import React from 'react';
import { Button as AntButton, ButtonProps as AntButtonProps } from 'antd';

interface CustomButtonProps extends Omit<AntButtonProps, 'type'> {
  label: string;
  buttonType?: 'primary' | 'secondary';
  noBorder?: boolean;
}

const CustomButton: React.FC<CustomButtonProps> = ({
  label,
  buttonType = 'primary',
  noBorder = false,
  ...props
}) => {
  const buttonClass = `
    ${buttonType === 'primary' ? 'custom-primary-button' : 'custom-secondary-button'}
    ${noBorder ? 'no-border' : ''}
  `;

  return (
    <AntButton {...props} className={buttonClass}>
      {label}
    </AntButton>
  );
};

export default CustomButton;
