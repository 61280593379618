import React, { Fragment } from 'react';
import { ModalType, useFormikEntryFilters } from '../../../../utils';
import { GoodsFilter } from '../../types';
import { useEntryStates, useSupervisors } from '../../../../share';
import GoodsCreateContainer from './GoodsCreateContainer';
import GoodsDetailContainer from './GoodsDetailContainer';
import { useFetchGoodsById, useFetchGoodsRequests } from '../../../../hooks';
import GoodsView from '../Views/GoodsView';

const GoodsContainer: React.FC = () => {
  const [filter, setFilter] = React.useState<GoodsFilter>();
  const { data: entryStates } = useEntryStates();
  const { data: supervisors } = useSupervisors();
  //manage state modal
  const [modalState, setModalState] = React.useState<{
    type: ModalType;
    id?: string;
  }>({ type: null, id: undefined });
  const {
    data: goods,
    isPending: loadList,
    refetch,
  } = useFetchGoodsRequests(filter);
  const { data: good, isPending: loadById } = useFetchGoodsById(modalState.id);
  const handleModalToggle = (type: ModalType, id?: string) => {
    setModalState((prev) => ({
      type: prev.type === type ? null : type,
      id: id,
    }));
  };

  const handlePagination = (page: number) => {
    setFilter({
      page: page.toString(),
    });
  };

  const handleFilter = async (values: GoodsFilter | {}) => {
    setFilter(values);
    refetch();
  };

  const formik = useFormikEntryFilters({ onSubmit: handleFilter });

  return (
    <Fragment>
      <GoodsCreateContainer
        isOpen={modalState.type === 'create'}
        OnClose={() => handleModalToggle('create')}
      />
      <GoodsDetailContainer
        isOpen={modalState.type === 'detail'}
        OnClose={() => handleModalToggle('detail')}
        data={good}
        isLoading={loadById}
      />

      <GoodsView
        isLoading={loadList}
        data={goods}
        supervisors={supervisors}
        states={entryStates}
        formik={formik}
        onViewCreate={() => handleModalToggle('create')}
        onViewDetail={(id) => handleModalToggle('detail', id)}
        onPagination={handlePagination}
        onResetFilters={() => {
          handleFilter({});
          formik.resetForm();
        }}
      />
    </Fragment>
  );
};

export default GoodsContainer;
