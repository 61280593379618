import React from 'react';
import EntryRequestCreateView from '../Views/EntryRequestCreateView';
import { CustomModal } from '../../../../components';
import { useCreateEntryReq } from '../../../../hooks/entryRequests';
import { UploadedFile, useEntryRequestFormik } from '../../../../utils';
import { EntryRequest } from '../../types';
import { useAtom } from 'jotai';
import { authAtom } from '../../../../state';

import {
  useDeliveryOptions,
  useDependecies,
  useEntryStates,
  useEntyTypes,
  useGoodStates,
  useItemTypes,
  useSedes,
  useSupervisors,
} from '../../../../share';

interface EntryRequestsCreateProps {
  isOpen: boolean;
  OnClose: () => void;
}

const EntryRequestCreateContainer: React.FC<EntryRequestsCreateProps> = ({
  isOpen,
  OnClose,
}) => {
  const [files, setFiles] = React.useState<UploadedFile[]>();
  const [authData] = useAtom(authAtom);
  const { mutateAsync, isPending: loadCreate } = useCreateEntryReq();
  const { data: entyTypes } = useEntyTypes();
  const { data: itemTypes } = useItemTypes();
  const { data: entryStates } = useEntryStates();
  const { data: goodStates } = useGoodStates();
  const { data: entryDevelivery } = useDeliveryOptions();

  const { data: sedes } = useSedes();
  const { data: supervisors } = useSupervisors();
  const { data: dependencies } = useDependecies();

  const onSave = async (values: EntryRequest) => {
    try {
      await mutateAsync({
        ...values,
        requester_id: authData?.user.id.toString(),
        invoice_date: new Date(values.invoice_date).toISOString().split('T')[0],
        documents: files,
      });
      onReset();
    } catch (error) {
      console.error('Error al guardar:', error);
    }
  };

  const formik = useEntryRequestFormik({ onSubmit: onSave });

  const handleSubmit = () => {
    formik.handleSubmit();
  };

  const onReset = () => {
    formik.resetForm();
    OnClose();
    setFiles([]);
  };

  return (
    <CustomModal
      title="Creación de solicitud de Entrada"
      labelSubmit="Crear Solicitud"
      open={isOpen}
      onCancel={() => {
        onReset();
      }}
      width={800}
      isLoadingSave={loadCreate}
      onSave={handleSubmit}
      onClose={() => {
        onReset();
      }}
    >
      <EntryRequestCreateView
        entyTypes={entyTypes}
        itemTypes={itemTypes}
        entryDevelivery={entryDevelivery}
        entryStates={entryStates}
        goodStates={goodStates}
        authData={authData}
        sedes={sedes}
        supervisors={supervisors}
        dependencies={dependencies}
        formik={formik}
        onSave={handleSubmit}
        onCancel={onReset}
        onFiles={setFiles}
        files={files}
      />
    </CustomModal>
  );
};

export default EntryRequestCreateContainer;
