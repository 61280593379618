import React from 'react';
import { Layout, Typography, Row, Col, Card } from 'antd';
import { Outlet } from 'react-router-dom';
const { Title } = Typography;

const AuthLayout: React.FC = () => {
  return (
    <Layout>
      <Outlet />
    </Layout>

    // <Layout style={{ minHeight: '100vh', backgroundColor: '#f0f2f5' }}>
    //   <Row
    //     justify="center"
    //     align="middle"
    //     style={{
    //       minHeight: '80vh',
    //     }}
    //   >
    //     <Col xs={22} sm={16} md={12} lg={8} xl={6}>
    //       <Card
    //         bordered={false}
    //         className="ant-card-body-center"
    //         style={{ textAlign: 'center' }}
    //       >

    //       </Card>
    //     </Col>
    //   </Row>
    // </Layout>
  );
};

export default AuthLayout;
