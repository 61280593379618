import React from 'react';
import { CustomModal } from '../../../../components';
import { useExportGoods } from '../../../../hooks/goodsRequest/useGoodRequest';
import RefundsDetailView from '../Views/RefundsDetailView';
import { RefundsRequest } from '../../types/refunds-response';

interface RefundsDetailProps {
  isOpen: boolean;
  OnClose: () => void;
  data?: RefundsRequest;
  isLoading: boolean;
}

const RefundsDetailContainer: React.FC<RefundsDetailProps> = ({
  isOpen,
  OnClose,
  data,
  isLoading,
}) => {
  const [footerLeft, setFooterLeft] = React.useState<React.ReactNode>(null);
  const { mutateAsync } = useExportGoods();

  return (
    <CustomModal
      title={`Detalle reintegro - ${data?.ticket || ''}`}
      open={isOpen}
      onCancel={OnClose}
      width={800}
      isLoading={isLoading}
      footerLeft={footerLeft}
    >
      <RefundsDetailView
        detailData={data}
        setFooterLeft={setFooterLeft}
        onDocuments={mutateAsync}
      />
    </CustomModal>
  );
};

export default RefundsDetailContainer;
