import apiClient from '../../services/apiClient';
import { ShareResponse } from '..';
import { LocationResponse } from '../types/location-response';
import { ItemResponse } from '../types/item-response';
import { AssetsResponse } from '../types/asset-response';

export const ShareAPI = {
  entryTypes: async (): Promise<ShareResponse[]> => {
    const response = await apiClient.get(`api/portal-utils/entry-types`);
    return response.data;
  },
  itemTypes: async (): Promise<ShareResponse[]> => {
    const response = await apiClient.get(`api/portal-utils/entry-items`);
    return response.data;
  },
  deliveryOptions: async (): Promise<ShareResponse[]> => {
    const response = await apiClient.get(`api/portal-utils/entry-deliver`);
    return response.data;
  },
  entryStates: async (): Promise<ShareResponse[]> => {
    const response = await apiClient.get(`api/portal-utils/entry-states`);
    return response.data;
  },

  goodstates: async (): Promise<ShareResponse[]> => {
    const response = await apiClient.get(`api/portal-utils/good-states`);
    return response.data;
  },

  //DEPENDICY
  dependencies: async (): Promise<ShareResponse[]> => {
    const response = await apiClient.get(
      `api/dependencies?paginate=0&filter[status_id]=1`
    );
    return response.data;
  },

  //AREA
  areas: async (): Promise<ShareResponse[]> => {
    const response = await apiClient.get(
      `api/area?paginate=0&filter[status_id]=1`
    );
    return response.data;
  },

  //SEDES
  sedes: async (): Promise<ShareResponse[]> => {
    const response = await apiClient.get(
      `api/branch?paginate=0&filter[status_id]=1`
    );
    return response.data;
  },

  //SUPERVISORES
  supervisors: async (): Promise<ShareResponse[]> => {
    const response = await apiClient.get(
      `api/utils/select-tables?keys[]=users`
    );
    return response?.data.users;
  },

  //ACTIVOS
  assets: async (): Promise<AssetsResponse[]> => {
    const response = await apiClient.get(
      `api/portal-utils/assets`
    );
    
    return response?.data;
  },
  
  //LOCATION
  location: async (): Promise<LocationResponse[]> => {
    const response = await apiClient.get(
      `api/locations?filter[status_id]=1&paginate=0`
    );

    return response?.data?.paginate?.data;
  },
  //ITEMS
  item: async (): Promise<ItemResponse[]> => {
    const response = await apiClient.get(
      `api/item?paginate=0&filter[status_id]=1`
    );
    return response.data;
  },
};
