import * as Yup from "yup";

export const loginValidationSchema = Yup.object({
  email: Yup.string().email("Correo inválido").required("Correo es requerido"),
  password: Yup.string()
    .min(6, "Debe tener al menos 6 caracteres")
    .required("Contraseña es requerida"),
});

export const transferValidationSchema = Yup.object({
  assetId: Yup.string().required("ID del activo es requerido"),
  requester: Yup.string().required("Solicitante es requerido"),
  newResponsible: Yup.string().required("Nuevo responsable es requerido"),
});
