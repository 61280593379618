import React, { Fragment } from 'react';
import { Tabs, Row, Col, Table, Button, Divider } from 'antd';
import {
  FileTextOutlined,
  FileSearchOutlined,
  HistoryOutlined,
  DownloadOutlined,
  FileProtectOutlined,
} from '@ant-design/icons';
import { CustomButton, CustomTable, CustomText } from '../../../../components';
import { TransfersRequest } from '../../types/transfers-response';

interface TransfersDetailViewProps {
  detailData?: TransfersRequest;
  setFooterLeft: (footer: React.ReactNode) => void;
  onVoucher?: () => void;
  onDocuments: (id: string[]) => void;
}

const TransfersDetailView: React.FC<TransfersDetailViewProps> = ({
  detailData,
  setFooterLeft,
  onVoucher,
  onDocuments,
}) => {
  // FOOTER
  React.useEffect(() => {
    setFooterLeft(
      <Fragment>
        <CustomButton
          label={'Generar comprobante'}
          onClick={onVoucher}
          icon={<FileProtectOutlined />}
          size="middle"
          noBorder
          buttonType={'secondary'}
        />
        <CustomButton
          label={'Descargar documentos'}
          onClick={() => {
            const documentIds = detailData?.documents
              ?.map((doc) => doc.id)
              .filter((id): id is string => id !== undefined);
            onDocuments(documentIds || []);
          }}
          icon={<DownloadOutlined />}
          size="middle"
          buttonType={'secondary'}
        />
      </Fragment>
    );
  }, [setFooterLeft]);

  const documentColumns = [
    {
      title: 'Id',
      dataIndex: 'index',
      key: 'index',
      render: (_: any, __: any, index: number) => index + 1,
    },
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Fecha creación',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: 'Archivo',
      dataIndex: 'link',
      key: 'link',
      render: (link: string) => (
        <Button type="link" href={link} target="_blank">
          Descargar
        </Button>
      ),
    },
  ];

  return (
    <Fragment>
      <Tabs
        defaultActiveKey="1"
        // centered
        items={[
          {
            key: '1',
            label: (
              <Button type="link" icon={<FileTextOutlined />}>
                Información General
              </Button>
            ),
            children: (
              <>
                <Row gutter={[16, 16]} style={{ marginBottom: '20px' }}>
                  <Col span={24}>
                    <div className="mb-4">
                      <CustomText text="Información traslado" strong fontSize="big" />
                    </div>
                  </Col>
                </Row>
                <Row gutter={[16, 16]} style={{ marginBottom: '20px' }}>
                  <Col span={6}>
                    <div className="mb-4">
                      <CustomText text="Solicitante:" strong fontSize="small" />
                      <CustomText
                        text={detailData?.requester || 'Por definir'}
                        fontSize="middle"
                      />
                    </div>
                  </Col>
                  <Col span={6}>
                    <div className="mb-4">
                      <CustomText text="Nuevo responsable:" strong fontSize="small" />
                      <CustomText
                        text={detailData?.new_responsible || 'Por definir'}
                        fontSize="middle"
                      />
                    </div>
                  </Col>
                  <Col span={6}>
                    <div className="mb-4">
                      <CustomText text="Dependencia:" strong fontSize="small" />
                      <CustomText
                        text={detailData?.dependency || 'Por definir'}
                        fontSize="middle"
                      />
                    </div>
                  </Col>
                </Row>
                <Row gutter={[16, 16]} style={{ marginBottom: '20px' }}>
                  <Col span={6}>
                    <div className="mb-4">
                      <CustomText text="Fecha:" strong fontSize="small" />
                      <CustomText
                        text={detailData?.query_date || 'Por definir'}
                        fontSize="middle"
                      />
                    </div>
                  </Col>
                  <Col span={6}>
                    <div className="mb-4">
                      <CustomText text="Estado de solicitud:" strong fontSize="small" />
                      <CustomText
                        text={detailData?.status || 'Por definir'}
                        fontSize="middle"
                      />
                    </div>
                  </Col>
                </Row>
              </>
            ),
          },
          {
            key: '2',
            label: (
              <Button type="link" icon={<FileSearchOutlined />}>
                Documentos
              </Button>
            ),
            children: (
              <>
                <Table
                  dataSource={detailData?.documents?.map((doc, index) => ({
                    key: index,
                    createdAt: doc.created_at,
                    name: doc.url?.split('/').pop(),
                    link: doc.url,
                  }))}
                  columns={documentColumns}
                />
                <Divider />
              </>
            ),
          },
          {
            key: '3',
            label: (
              <Button type="link" icon={<HistoryOutlined />}>
                Historial de la Solicitud
              </Button>
            ),
            children: (
              <CustomTable
                columns={[
                  {
                    title: 'Id',
                    dataIndex: 'index',
                    key: 'index',
                    render: (_: any, __: any, index: number) => index + 1,
                  },
                  {
                    title: 'Fecha de Solicitud',
                    dataIndex: 'date',
                    key: 'date',
                  },
                  {
                    title: 'Estado',
                    dataIndex: 'comment',
                    key: 'comment',
                  },
                ]}
                dataSource={detailData?.history?.map((item, index) => ({
                  key: index,
                  date: item.date,
                  comment: item.comment,
                }))}
                pagination={false}
              />
            ),
          },
        ]}
      />
    </Fragment>
  );
};

export default TransfersDetailView;
