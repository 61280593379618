import React from 'react';
import { Button, Row, Col, Typography, Card, Divider } from 'antd';
import { ArrowLeftOutlined, PlusOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../Button/Button';

const { Title } = Typography;

interface GeneralContainerProps {
  title: string;
  actionLabel?: string;
  onActionClick?: () => void;
  isGoBack?: boolean;
  children: React.ReactNode;
  isLoading?: boolean;
}

const GeneralContainer: React.FC<GeneralContainerProps> = ({
  title,
  actionLabel,
  onActionClick,
  isGoBack = false,
  children,
  isLoading = false,
}) => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <Card className="general-container">
      <Row justify="space-between" align="middle" gutter={16}>
        {isGoBack && (
          <Col>
            <Button
              variant="filled"
              icon={<ArrowLeftOutlined />}
              onClick={handleGoBack}
              size="large"
            >
              Volver
            </Button>
          </Col>
        )}
        <Col flex="auto" className="container-title">
          <Title level={3}>{title}</Title>
        </Col>
        {actionLabel && onActionClick && (
          <Col>
            <CustomButton
              disabled={isLoading}
              icon={<PlusOutlined />}
              label={actionLabel}
              onClick={onActionClick}
              size="large"
            />
          </Col>
        )}
      </Row>
      <div>{children}</div>
    </Card>
  );
};

export default GeneralContainer;
