import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useCustomToast } from '../../components';
import {
  PetitionsFilter,
  PetitionsResponse,
} from '../../modules/Petitions/types';
import { PetitionsReqAPI } from '../../modules/Petitions/api';

// Query key for caching
const keyQuery = ['petitions'] as const;

export const usePetitions = (filters?: Partial<PetitionsFilter>) => {
  return useQuery<PetitionsResponse, Error>({
    queryKey: [keyQuery, filters || {}],
    queryFn: ({ queryKey }) => PetitionsReqAPI.gets({ queryKey }),
    enabled: true,
  });
};

export const usePetitionsById = (id?: string) => {
  return useQuery({
    queryKey: [...keyQuery, id],
    queryFn: () => (id ? PetitionsReqAPI.get(id) : Promise.resolve(undefined)),
    enabled: !!id,
  });
};

export const useCreatePetitions = () => {
  const { showToast } = useCustomToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: PetitionsReqAPI.post,
    onSuccess: () => {
      showToast('success', 'Petition Created');
      queryClient.invalidateQueries({ queryKey: [keyQuery, {}] });
    },
  });
};

// Delete a goods request
export const useDeletePetitios = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: string) => PetitionsReqAPI.delete(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: keyQuery });
    },
  });
};

//Export excel

export const useExportPetitions = () => {
  const { showToast } = useCustomToast();
  return useMutation({
    mutationFn: (ids: string[]) => PetitionsReqAPI.export(ids),
    onSuccess: () => {
      showToast('success', 'Export successful');
    },
    onError: () => {
      showToast('error', 'Failed to export');
    },
  });
};
