import React from 'react';
import { Form, Input } from 'antd';

interface FormTextAreaProps {
  label: string;
  name: string;
  placeholder?: string;
  value: string | undefined;
  error?: string | undefined;
  touched?: boolean | undefined;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  disabled?: boolean;
  rows?: number;
}

const CustomTextArea: React.FC<FormTextAreaProps> = ({
  label,
  name,
  placeholder,
  value,
  error,
  touched,
  onChange,
  onBlur,
  disabled = false,
  rows = 2,
}) => (
  <Form.Item
    label={<span className="custom-label">{label}</span>}
    validateStatus={touched && error ? 'error' : undefined}
    help={touched && error}
    labelCol={{ span: 24 }}
    className="custom-form-item"
  >
    <Input.TextArea
      name={name}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      rows={rows}
      disabled={disabled}
    />
  </Form.Item>
);

export default CustomTextArea;
