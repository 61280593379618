import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { TransfersFilter, TransfersResponse } from "../../modules/Transfers/types/transfers-response";
import { transfersReqAPI } from "../../modules/Transfers/api/transfersAPI";
import { useCustomToast } from "../../components";

// Query key for caching
const keyQuery = ['transfers'] as const;

export const useFetchTransferRequests = (filters?: Partial<TransfersFilter>) => {
  return useQuery<TransfersResponse, Error>({
    queryKey: [keyQuery, filters || {}],
    queryFn: ({ queryKey }) => transfersReqAPI.gets({ queryKey }),
    enabled: true,
  });
};

// Fetch a single transfer request by ID
export const useFetchTransferById = (id?: string) => {
  return useQuery({
    queryKey: [...keyQuery, id],
    queryFn: () => (id ? transfersReqAPI.get(id) : Promise.resolve(undefined)),
    enabled: !!id,
  });
};

// Create a new transfer request
export const useCreateTransfer = () => {
  const { showToast } = useCustomToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: transfersReqAPI.post,
    onSuccess: () => {
      showToast('success', 'Transfer Created');
      queryClient.invalidateQueries({ queryKey: [keyQuery, {}] });
    },
  });
};

//Export excel

export const useExportTransfer = () => {
  const { showToast } = useCustomToast();
  return useMutation({
    mutationFn: (ids: string[]) => transfersReqAPI.export(ids),
    onSuccess: () => {
      showToast('success', 'Export successful');
    },
    onError: () => {
      showToast('error', 'Failed to export');
    },
  });
};
