import React from 'react';
import { CustomModal } from '../../../../components';
import { PetitionsRequest } from '../../types/petitions-response';

import { useExportPetitions } from '../../../../hooks';
import PetitionsDetailView from '../Views/PetitionsDetailView';

interface PetitionsDetailProps {
  isOpen: boolean;
  OnClose: () => void;
  data?: PetitionsRequest;
  isLoading: boolean;
}

const PetitionsDetailContainer: React.FC<PetitionsDetailProps> = ({
  isOpen,
  OnClose,
  data,
  isLoading,
}) => {
  const [footerLeft, setFooterLeft] = React.useState<React.ReactNode>(null);
  const { mutateAsync } = useExportPetitions();

  return (
    <CustomModal
      title={`Detalle Mis Solicitudes - ${data?.ticket || ''}`}
      open={isOpen}
      onCancel={OnClose}
      width={800}
      isLoading={isLoading}
      footerLeft={footerLeft}
    >
      <PetitionsDetailView
        detailData={data}
        setFooterLeft={setFooterLeft}
        onDocuments={mutateAsync}
      />
    </CustomModal>
  );
};

export default PetitionsDetailContainer;
