import React from 'react';
import { Upload, Table, Row, Col, Button } from 'antd';
import { UploadOutlined, DeleteOutlined } from '@ant-design/icons';
import { UploadedFile } from '../../utils';

interface UploadWithTableProps {
  onFilesChange: (files: UploadedFile[]) => void;
  files?: UploadedFile[];
}

const UploadWithTable: React.FC<UploadWithTableProps> = ({
  onFilesChange,
  files,
}) => {
  const columns = [
    {
      title: 'Nombre del archivo',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Acciones',
      dataIndex: 'actions',
      key: 'actions',
      render: (_: any, record: { file: File }) => (
        <Button type="link" onClick={() => handleDownload(record.file)}>
          Descargar
        </Button>
      ),
    },
    {
      title: 'Eliminar',
      key: 'delete',
      render: (_: any, record: UploadedFile) => (
        <Button
          type="link"
          icon={<DeleteOutlined />}
          onClick={() => handleDelete(record.key)}
        >
          Eliminar
        </Button>
      ),
    },
  ];

  const handleDownload = (file: File) => {
    const url = URL.createObjectURL(file);
    const link = document.createElement('a');
    link.href = url;
    link.download = file.name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDelete = (key: string) => {
    const updatedFiles = files?.filter((file) => file.key !== key);
    onFilesChange(updatedFiles || []);
  };

  const handleUploadChange = (info: any) => {
    const newFiles = info.fileList.map((file: any, index: number) => ({
      key: `${index}`,
      name: file.name,
      file: file.originFileObj,
    }));
    onFilesChange(newFiles);
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <Upload.Dragger
            name="files"
            multiple
            showUploadList={false}
            beforeUpload={() => false}
            onChange={handleUploadChange}
          >
            <p className="ant-upload-drag-icon">
              <UploadOutlined />
            </p>
            <Button
              type="primary"
              icon={<UploadOutlined />}
              style={{ marginBottom: '10px' }}
            >
              Subir Archivos
            </Button>
            <p className="ant-upload-hint">
              Arrastre los archivos y suéltelos aquí
            </p>
          </Upload.Dragger>
        </Col>
      </Row>
      <Table
        dataSource={files}
        columns={columns}
        pagination={false}
        className="mt-16"
      />
    </>
  );
};

export default UploadWithTable;
