import React from 'react';
import { Button, Row, Col, Alert } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import { CustomTable, GeneralContainer } from '../../../../components';
import SearchRow from '../components/SearchFilters';
import { EntryRequestResponse } from '../../types/entry-response';
import { ShareResponse } from '../../../../share';
import { EntryStatus } from '../../types';

interface EntryRequestsViewProps {
  data?: EntryRequestResponse;
  onViewCreate: () => void;
  onPagination: (page: number, pageSize: number) => void;
  onViewDetail: (id: string) => void;
  formik: any;
  states?: ShareResponse[];
  isLoading: boolean;
  onResetFilters: () => void;
  supervisors?: ShareResponse[];
}

const EntryRequestsView: React.FC<EntryRequestsViewProps> = ({
  data,
  onViewCreate,
  onViewDetail,
  onPagination,
  formik,
  states,
  isLoading,
  onResetFilters,
  supervisors,
}) => {
  const columns = [
    {
      title: 'Número Ticket',
      dataIndex: 'ticket',
      key: 'ticket',
      sorter: true,
      align: 'center',
    },
    {
      title: 'Tipo Ticket',
      dataIndex: 'ticket_type',
      key: 'ticket_type',
      sorter: true,
      align: 'center',
      responsive: ['lg'],
    },
    {
      title: 'Tipo de Bien',
      dataIndex: 'item_type',
      key: 'item_type',
      sorter: true,
      align: 'center',
    },
    {
      title: 'Tipo de Bien',
      dataIndex: 'item_type',
      key: 'item_type',
      sorter: true,
      align: 'center',
      responsive: ['lg'],
    },

    {
      title: 'Fecha de Solicitud',
      dataIndex: 'query_date',
      key: 'query_date',
      sorter: true,
      align: 'center',
    },
    {
      title: 'Estado de Solicitud',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      render: (status: string) => {
        const statusTypeMap: Record<
          EntryStatus,
          'success' | 'info' | 'warning' | 'error'
        > = {
          [EntryStatus.NUEVO]: 'info',
          [EntryStatus.TRAMITE_INGRESO]: 'warning',
          [EntryStatus.DEVUELTO]: 'error',
          [EntryStatus.ENVIADO]: 'info',
          [EntryStatus.FINALIZADO]: 'success',
        };

        const type = statusTypeMap[status as EntryStatus] || 'info';
        const message = status || 'Desconocido';

        return <Alert message={message} type={type} showIcon />;
      },
    },
    {
      title: 'Solicitante',
      dataIndex: 'requester',
      key: 'requester',
      align: 'center',
      responsive: ['lg'],
    },
    {
      title: 'Observaciones',
      dataIndex: 'observations',
      key: 'observations',
      align: 'center',
      render: (text: string) => (text ? text : 'Sin observaciones'),
    },
    {
      title: 'Acciones',
      key: 'actions',
      align: 'center',
      render: (_: any, record: { id: number }) => (
        <Button
          type="link"
          icon={<EyeOutlined />}
          onClick={() => onViewDetail(record.id.toString())}
        />
      ),
    },
  ];

  return (
    <GeneralContainer
      title="Solicitudes de Entrada"
      actionLabel="Crear Solicitud"
      onActionClick={onViewCreate}
      isLoading={isLoading}
    >
      {/* Filtros */}
      <Row gutter={[16, 16]} style={{ marginBottom: '16px' }}>
        <Col xs={24}>
          <SearchRow
            supervisors={supervisors}
            formik={formik}
            states={states}
            onCancel={onResetFilters}
            isLoading={isLoading}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={24}>
          <CustomTable
            loading={isLoading}
            columns={columns as any}
            dataSource={data?.data?.map((entry) => ({
              ...entry,
              id: Number(entry.id) || -1,
            }))}
            paginationConfig={{
              current: data?.current_page,
              pageSize: data?.per_page,
              total: data?.total,
              onChange: onPagination,
            }}
            scroll={{ x: 'max-content' }}
          />
        </Col>
      </Row>
    </GeneralContainer>
  );
};

export default EntryRequestsView;
