import { useFormik } from 'formik';
import * as Yup from 'yup';
import { EntryRequest } from '../../../modules/EntryRequests/types';

// Esquema de validación
export const entryRequestValidationSchema = Yup.object({
  entry_type: Yup.string().required('Requerido'),
  item_type: Yup.string().required('Requerido'),
  branch_id: Yup.string().required('Requerido'),
  deliveries: Yup.string()
    .min(1, 'Debe haber al menos una entrega')
    .required('Requerido'),
  section_id: Yup.string().required('Requerido'),
  supervisor_id: Yup.string().required('Requerido'),
  invoice_number: Yup.string().required('Requerido'),
  other_deliver: Yup.string().required('Requerido'),
  invoice_date: Yup.date().nullable().required('Requerido'),
  invoice_value: Yup.number()
    .min(0, 'El valor debe ser positivo')
    .required('Requerido'),
  observations: Yup.string().max(500, 'Máximo 500 caracteres'),
});

// Hook customizado
interface UseEntryRequestFormikProps {
  onSubmit: (values: EntryRequest) => void;
}

const useEntryRequestFormik = ({ onSubmit }: UseEntryRequestFormikProps) => {
  return useFormik<EntryRequest>({
    initialValues: {
      entry_type: '',
      item_type: '',
      branch_id: '',
      deliveries: '',
      section_id: '',
      supervisor_id: '',
      other_deliver: '',
      invoice_number: '',
      invoice_date: '',
      invoice_value: '',
      observations: '',
    },
    validationSchema: entryRequestValidationSchema,
    onSubmit,
  });
};

export default useEntryRequestFormik;
