import { useFormik } from 'formik';
import * as Yup from 'yup';

interface UseFormikPetitionFiltersProps {
  onSubmit: (values: any) => void;
}

const useFormikPetitionFilters = ({
  onSubmit,
}: UseFormikPetitionFiltersProps) => {
  const initialValues = {
    ticket: '',
    requester_id: '',
    status_id: '',
    created_at: '',
  };

  const validationSchema = Yup.object({
    id: Yup.string(),
    requester_id: Yup.string(),
    status_id: Yup.string(),
    created_at: Yup.date().nullable(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, actions) => {
      onSubmit(values);
      actions.setSubmitting(false);
    },
  });

  return formik;
};

export default useFormikPetitionFilters;
