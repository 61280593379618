import { useFormik } from "formik";
import * as Yup from 'yup';
import { TransfersRequest } from "../../../modules/Transfers/types/transfers-response";
import { DateTime } from "luxon";

// Esquema de validación
export const transferRequestValidationSchema = Yup.object({
    requester_id: Yup.string(),
    query_date: Yup.date(),
    dependency_id: Yup.string().required('Requerido'),
    new_dependency_id: Yup.string().required('Requerido'),
    new_responsible_id: Yup.string().required('Requerido'),
    status_id: Yup.string().required('Requerido'),
    asset_class: Yup.string(),
    item: Yup.string(),
    name: Yup.string(),
    plate_code: Yup.string(),
    status: Yup.string(),
  });
  
  // Hook customizado
  interface UseTransferRequestFormikProps {
    onSubmit: (values: Partial<TransfersRequest>) => void;
  }
  
  const useTransferRequestFormik = ({ onSubmit }: UseTransferRequestFormikProps) => {
    return useFormik<Partial<TransfersRequest>>({
      initialValues: {
        query_date: DateTime.now().toFormat('yyyy-MM-dd'),
        requester_id: '',
        dependency_id: '',
        location_id: '',
        new_responsible_id: '',
        status_id: '',
      },
      validationSchema: transferRequestValidationSchema,
      onSubmit,
    });
  };
  
  export default useTransferRequestFormik;
