const blacklist = ['page'];

export function TransformFilters<T extends Record<string, any>>(
  filters: T | undefined
) {
  const transformedFilters = Object.entries(filters || {}).reduce(
    (acc, [key, value]) => {
      if (
        !blacklist.includes(key) &&
        value !== undefined &&
        value !== null &&
        value !== ''
      ) {
        acc[`filter[${key}]`] = String(value);
      }
      return acc;
    },
    {} as Record<string, string>
  );
  return transformedFilters;
}
