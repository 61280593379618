import React, { Fragment } from 'react';
import { ModalType, useFormikEntryFilters } from '../../../../utils';
import RefundsView from '../Views/RefundsView';
import { RefundsFilter } from '../../types/refunds-response';
import { useFetchRefundById, useFetchRefundRequests } from '../../../../hooks';
import { useGoodStates } from '../../../../share';
import RefundsCreateContainer from './RefundsCreateContainer';
import RefundsDetailContainer from './RefundsDetailContainer';

const RefundsContainer: React.FC = () => {
  const [filter, setFilter] = React.useState<RefundsFilter>();
  const { data: refundStates } = useGoodStates();
  //manage state modal
  const [modalState, setModalState] = React.useState<{
    type: ModalType;
    id?: string;
  }>({ type: null, id: undefined });
  const {
    data: refunds,
    isPending: loadList,
    refetch,
  } = useFetchRefundRequests(filter);
  const { data: refund, isPending: loadById } = useFetchRefundById(modalState.id);

  const handleModalToggle = (type: ModalType, id?: string) => {
    setModalState((prev) => ({
      type: prev.type === type ? null : type,
      id: id,
    }));
  };

  const handlePagination = (page: number) => {
    setFilter({
      page: page.toString(),
    });
  };

  const handleFilter = async (values: RefundsFilter | {}) => {
    setFilter(values);
    refetch();
  };

  const formik = useFormikEntryFilters({ onSubmit: handleFilter });
  
  return (
    <Fragment>
      <RefundsCreateContainer
        isOpen={modalState.type === 'create'}
        OnClose={() => handleModalToggle('create')}
      />
      
      <RefundsDetailContainer
        isOpen={modalState.type === 'detail'}
        OnClose={() => handleModalToggle('detail')}
        data={refund}
        isLoading={loadById}
      />
      
      <RefundsView
        isLoading={loadList}
        data={refunds}
        states={refundStates}
        formik={formik}
        onViewCreate={() => handleModalToggle('create')}
        onViewDetail={(id) => handleModalToggle('detail', id)}
        onPagination={handlePagination}
        onResetFilters={() => {
          handleFilter({});
          formik.resetForm();
        }}
      />
    </Fragment>
  );
};

export default RefundsContainer;
